<template>
    <div>
      
    </div>
</template>


<script>
//此页面为空白页-为相同路由二次点击跳转
export default {
    created(){
        this.$router.push({
          path: "/systemSettings/setuppage/Administration",
        });
    }
}
</script>


<style lang="scss" scoped>

</style>